<template>
  <div class="flex justify-between">
  <div class="w-6/12 bg-gray-100 border border-gray-400 p-4 mr-6">
    <div class="section  ">
       <p class="font-bold text-lg mb-4">
          instructions box:
      </p>
    </div>
  </div>
  <div class="w-6/12">
    <div class="form-row">
      <div class="form-col  ">
        <label for="current_unit_number">current unit number</label>
        <input  type="number" class="form-input" id="current_unit_number"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label for="new_unit_number">new unit number</label>
        <input  type="number" class="form-input" id="new_unit_number"/>
      </div>
    </div>
    <div class="form-row" v-for="(residentName, i) in residentNames" :key="i">
      <div class="form-col">
        <label v-if="i>0">resident number (if applicable)</label>
        <label v-else>resident number</label>
        <div class="flex">
          <input  type="number" class="form-input" id="current_unit_number"/>
          <button
              class="text-red-700 focus:outline-none ml-2"
              type="button"
          >
            <icon name="times" class="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
    <div>
      <button
        class="flex items-center border-b font-bold focus:outline-none  button-color"
        type="button"
      >
        add new <icon name="plus" class="w-5 h-5 ml-2 color-text" />
      </button>
    </div>
    <div class="w-full flex pt-5">
      <p class="font-bold mr-2 mt-1">create a new lease?</p> 
      <toggle-input />
    </div>
    <div class="w-full flex pt-5">
      <p class="font-bold mr-2 mt-1">need to rescreen remaining resident(s)?</p> 
      <toggle-input />
    </div>
    <div class="w-full flex pt-5">
      <p class="font-bold mr-2 mt-1">need to rescreen transfering resident(s)?</p> 
      <toggle-input />
    </div>
  </div>
    
    <modal-footer :footer="footer" :primary="save" :secondary="cancel"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
import Icon from '@/components/ui/Icon';
import ToggleInput from "@/components/ui/ToggleInput";
import ModalFooter from '@/components/ui/modals/ModalFooter'

export default {
  name:'transfer resident',
  mixins: [
    ModalNavigation,
    NotifyMixin
  ],
  components:{
    Icon,
    ToggleInput,
    ModalFooter
  },
  data() {
    return {
      residentNames: [1,2,3],
      footer: {
        primaryButton: 'next',
        secondaryButton: 'cancel',
      },
    }
  },
  methods: {
    save(){
      this.$router.push({name: 'transfer_resident.create-lease'});
    },
    cancel(){
      this.$router.push({name: 'bookmarks'});
    }
  }
}
</script>

<style scoped>
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}


</style>